footer {
	position: sticky;
	top: 100%;
	color: #ffffff;
	padding: 0 40px;
}

.box-footer {
	max-width: 1100px;
	margin: auto;
	border-top: 1px solid #0D2938;
	padding: 20px 0px 70px 0px;
	background: #1D3745;
	
}

.font20 {
	font-size: 20px;
}

.margin-bottom5 {
	margin-bottom: 5px;
}

.grid-row10 {
	display: grid;
	gap: 10px;
}

.grid-footer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	gap: 10px;
	padding-top: 50px;
	align-items: flex-start;
}

.logo-footer {
	width: 100px;
	height: auto;
	cursor: pointer;
}

.nav-footer {
	display: grid;
	grid-auto-flow: dense;
	gap: 15px;
	align-items: flex-start;
	
}

.nav-footer > * {
	color: #ffffff;
	font-family: 'Lato', sans-serif;
	text-align: left;
	font-size: 16px;
	line-height: 22px;
}

.grid-social {
	display: grid;
	grid-auto-flow: column;
	gap: 40px;
	align-items: center;
	justify-content: flex-start;
}

.icon-social {
	width: 30px;
	height: auto;
}

.tw {
	width: 35px;
	height: auto;
}

/********************* MEDIA QUERY ************************/

@media (max-width: 960px) {
	footer {
		padding: 0px 30px;
	}
	.grid-footer {
		padding-top: 20px;
	}
}

@media(min-width: 769px) {
	.none-min768f{
		display: none;
	}
}

@media (max-width: 768px) {
	.grid-footer {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	
	.grid-social {
		gap: 20px;
	}
	
	.logo-footer {
		grid-row: 1;
	}
	.none768f{
		display: none;
	}
	.logo-footer {
		margin-bottom: 30px;
	}
}

@media (max-width: 550px) {
	footer {
		padding: 0px 20px;
	}
	.logo-footer {
		margin-bottom: 25px;
	}
	
}


@media (max-width: 510px) {
	.grid-footer {
		grid-template-columns: 1fr 1fr 1fr;
	}
	
	.social520 {
		grid-column: 1 / 4;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
	}
	
	.total-center-520 > *{
		text-align: center;
	}
	
	.logo-footer {
		margin: auto;
		padding-bottom: 20px;
	}
}
