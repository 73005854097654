.icon-menu{
    background-image: url("./deedly/icon-menu.svg");
}

.icon-x{
    background-image: url("./deedly/icon-x.svg");
}

.icon-tech{
    background-image: url("./deedly/icon-tech.png");
}

.icon-down{
    background-image: url("./deedly/icon-dwn.png");
}

.icon-self{
    background-image: url("./deedly/icon-self.png");
}

.icon-earth{
    background-image: url("./deedly/icon-earth.png");
}

.icon-community{
    background-image: url("./deedly/icon-community.png");
}

.icon-stories{
    background-image: url("./deedly/icon-stories.png");
}

.icon-next{
    background-image: url("./deedly/icon-next.svg");
}

.icon-prev{
    background-image: url("./deedly/icon-next.svg");
    transform: rotateZ(180deg);
}

.arrow{
    position: absolute;
    top:50%;
    margin-top: -2rem;

    z-index: 1000;
}

.arrow.next{
    left: auto;
}